<template>
  <q-btn
    flat
    v-if="showBtnType && !isPermissionDenied"
    @click="queryCurrentLocation"
    :loading="loading"
    icon="my_location"
  >
    <!-- <SvgIcon size="1.5rem" icon="search-location" /> -->
  </q-btn>
  <a
    href="javascript:void(0)"
    class="no-underline allowLocation"
    v-else-if="!showBtnType && !isPermissionDenied"
    @click="queryCurrentLocation"
  >
    <q-spinner
      v-if="showLoader && loading"
      icon="search-location"
      class="q-mr-sm q-ml-sm"
    />
    <SvgIcon v-else icon="search-location" class="q-mr-sm q-ml-sm" />
    <span>Use my current location</span>
  </a>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UseMyCurrentLocation',
  props: {
    locationFound: {
      type: Function,
      default: null,
    },
    setLoading: {
      type: Function,
      default: () => {},
    },
    showBtnType: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: true,
    },
    queryOnMount: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('geolocation', ['isPermissionDenied']),
    ...mapGetters('location', ['latitude', 'longitude']),
  },
  data() {
    return {
      loading: false,
      myLocation: null,
    }
  },
  methods: {
    queryCurrentLocation() {
      if (this.loading || this.isPermissionDenied) return
      if (this.myLocation) return this.locationFound(this.myLocation)

      this.loading = true
      this.setLoading(true)

      this.$store
        .dispatch('geolocation/samplePosition')
        .then(async (data) => {
          if (data && data.coords) {
            let { latitude } = data.coords,
              { longitude } = data.coords

            if (this.latitude != latitude || this.longitude != longitude) {
              this.$store.commit('location/UPDATE_CHANGE_LOCATION_FIELD', {
                field: 'latitude',
                latitude,
              })

              this.$store.commit('location/UPDATE_CHANGE_LOCATION_FIELD', {
                field: 'longitude',
                longitude,
              })

              let data = await this.$store.dispatch('location/getMyLocation', {
                LatitudeLongitude: `${latitude},${longitude}`,
              })

              if (
                this.locationFound &&
                typeof this.locationFound === 'function' &&
                data.data
              ) {
                let myLocation = {
                  ...data.data,
                }

                this.locationFound(myLocation)
                this.myLocation = myLocation
              }

              this.loading = false
              this.setLoading(false)
            }
          }
        })
        .catch(() => {
          this.loading = false
          this.setLoading(false)
        })
        .finally(() => {
          this.$store.dispatch('geolocation/queryPermission')
        })
    },
  },
  async mounted() {
    if (!this.queryOnMount) return
    await this.$store.dispatch('geolocation/queryPermission')
    if (!this.currentLocationId) this.queryCurrentLocation()
  },
}
</script>
